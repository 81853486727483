<template>
  <div class="base-container">
    <sidebar-fluid />
    <main :class="mainClassesComputed">
      <slot name="banner"></slot>
      <b-container fluid>
        <div :class="additionalWrapperClasses">
          <div v-if="hasHeader" class="page-heading">
            <div class="white-space-nowrap d-flex">
              <b-button
                @click="doBack()"
                variant="link"
                size="sm"
                class="ms-n2"
                v-if="hasBack"
              >
                <font-awesome-icon
                  icon="chevron-left"
                  class="fa-fw"
                />
              </b-button>
              <h1 class="page-title white-space-normal">{{title}}</h1>
            </div>
            <div
              class="page-title__action ms-md-auto"
              v-if="hasButton"
            >
              <b-button
                @click="doCtaAction"
                size="sm"
                :variant="buttonVariant"
              >
                {{buttonContent}}
              </b-button>
            </div>
          </div>
          <slot name="shoutbox" v-if="hasShoutbox"></slot>
          <div v-if="extendedStatus.userStatus === 'wait'">
            <div v-if="isDashboardCheck" class="d-flex justify-content-center">
              <div class="dashcard m-auto w-auto p-4 mt-n5 mb-5">
                <div class="dashcard__body d-flex flex-column align-items-center">
                  <div class="icon-warning-email">
                    <font-awesome-icon icon="envelope" class="fa-fw" />
                    <div>
                      <font-awesome-icon icon="exclamation" class="fa-fw" />
                    </div>
                  </div>
                  <h5 class="text-center text-uppercase">
                    PRIMA DI POTER UTILIZZARE I NOSTRI SERVIZI
                    <br>
                    è NECESSARIO CONFERMARE L'INDIRIZZO EMAIL:
                    <br>
                  </h5>
                  <h6>
                    <strong>
                      {{entityEmail}}
                    </strong>
                  </h6>
                  <b-button
                    variant="warning"
                    @click="sendTokenRequest"
                    class="mt-3 mb-5"
                  >
                    Re-invia email di verifica
                  </b-button>
                </div>
              </div>
            </div>
            <div
              class="d-flex flex-column align-items-center"
              v-else
            >
              <div class="icon-warning-email">
                <font-awesome-icon icon="envelope" class="fa-fw" />
                <div>
                  <font-awesome-icon icon="exclamation" class="fa-fw" />
                </div>
              </div>
              <h5 class="text-center text-uppercase">
                PRIMA DI POTER UTILIZZARE I NOSTRI SERVIZI
                <br>
                è NECESSARIO CONFERMARE L'INDIRIZZO EMAIL:
                <br>
              </h5>
              <h6>
                <strong>
                  {{entityEmail}}
                </strong>
              </h6>
              <b-button
                variant="warning"
                @click="sendTokenRequest"
                class="mt-3 mb-5"
              >
                Re-invia email di verifica
              </b-button>
            </div>
          </div>
          <div
            v-if="extendedStatus.userStatus !== 'wait'"
          >
            <div
              class="my-3"
              v-if="!isActiveUser"
            >
              <div v-if="isDashboardCheck" class="d-flex justify-content-center">
                <div class="dashcard m-auto w-auto p-4 mt-n5 mb-5">
                  <div class="dashcard__body d-flex flex-column align-items-center">
                    <h5 class="text-center mb-0">
                      PROFILO IN FASE DI ATTIVAZIONE:
                      <br />
                      I nostri operatori stanno controllando i tuoi dati. Una volta verificati
                      riceverai una mail di conferma di attivazione del tuo account e potrai
                      iniziare ad usufruire dei nostri servizi. Solitamente il processo richiede poche ore.
                    </h5>
                  </div>
                </div>
              </div>
              <div v-else>
                <h5 class="text-center mb-0">
                  COMPLETA I TUOI DATI ANAGRAFICI E ACCETTA IL CONTRATTO DI UTILIZZO DELLA PIATTAFORMA
                  <br />
                  PER OTTENERE IL TUO BADGE, COLLEZIONARE FEEDBACK ED USUFRUIRE DEI SERVIZI PER LE TUE
                  AZIENDE
                </h5>
              </div>
            </div>
            <user-toolbar
              :serialCode="serialCode"
              :currentName="currentName"
              :hide-button="hideButton"
              :hide-switch="hideSwitch"
              :hasCreateAnalysis="hasCreateAnalysis"
              :hasCreateReport="hasCreateReport"
              v-if="!noToolbarComputed"
            />
            <div :class="bodyClasses">
              <slot name="body"></slot>
            </div>
          </div>
        </div>
      </b-container>
    </main>
  </div>
</template>

<script>
import { extractErrorMessage, isNotEmpty } from '@/utils/validators';

const UserToolbar = () => import('@/components/userSections/UserToolbar.vue');
const SidebarFluid = () => import('./SidebarFluid.vue');

export default {
  name: 'UserStructure',
  // components: { UserToolbar, Sidebar, SidebarFluid },
  components: { UserToolbar, SidebarFluid },
  props: {
    hideButton: Boolean,
    noToolbar: Boolean,
    hideSwitch: Boolean,
    hasCreateAnalysis: Boolean,
    hasCreateReport: Boolean,
    mainClasses: String,
    title: String,
    titleButton: Object,
    titleButtonAction: Function,
    hasBack: Boolean,
    isTabbed: Boolean,
    isDashboard: Boolean,
  },
  data() {
    return {
      message: null,
      submitted: false,
      notValid: false,
      requestType: ['pr_company', 'pr_person'],
    };
  },
  computed: {
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    extendedStatus() {
      return this.$store.getters['subject/currentExtendedStatus'];
    },
    isActiveUser() {
      return this.$store.getters['subject/isActiveUser'];
    },
    hasScoreData() {
      // if (this.hasLoaded !== this.$store.getters['score/hasCurrentScore']) {
      //   this.changePreloaderStatus(this.$store.getters['score/hasCurrentScore']);
      // }
      return this.$store.getters['score/hasCurrentScore'];
    },
    serialCode() {
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return score.serialCode;
      }
      return '';
    },
    entityEmail() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/currentCompany'].attribute.mainPecEmail.contactData;
      }
      return this.$store.getters['subject/currentPerson']?.personInfo.email;
    },
    currentName() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/currentCompany'].info.companyName;
      }
      return this.$store.getters['auth/currentName'];
    },
    mainClassesComputed() {
      const baseClasses = 'main--inside-content';
      if (this.isDashboard) return `${baseClasses} main--inside-content--is-dashboard`;
      return isNotEmpty(this.mainClasses) ? `${baseClasses} ${this.mainClasses}` : baseClasses;
    },
    noToolbarComputed() {
      // return this.noToolbar;
      return true;
    },
    hasShoutbox() {
      return false;
    },
    hasHeader() {
      return isNotEmpty(this.title) && this.extendedStatus.userStatus !== 'wait';
    },
    hasButton() {
      if (isNotEmpty(this.titleButton?.visibility)) return this.titleButton.visibility;
      return isNotEmpty(this.titleButton);
    },
    buttonContent() {
      if (isNotEmpty(this.titleButton?.content)) return this.titleButton.content;
      return 'CTA';
    },
    buttonVariant() {
      if (isNotEmpty(this.titleButton?.variant)) return this.titleButton.variant;
      return 'info';
    },
    hasRoute() {
      return isNotEmpty(this.titleButton?.route);
    },
    hasAction() {
      return isNotEmpty(this.titleButton?.action);
    },
    bodyClasses() {
      // return !this.isTabbed ? 'p-3 bg-white mb-3' : '';
      return !this.isTabbed ? 'mb-3' : '';
    },
    isDashboardCheck() {
      return this.isDashboard;
    },
    additionalWrapperClasses() {
      return this.isDashboardCheck ? '' : 'px-3';
    },
  },
  mounted() {
    if (!this.$store.getters['subject/isLoadedData']) {
      this.fetchData();
    } else {
      this.$store.dispatch('setPreloader', false);
    }
    this.loadProducts();
    this.$store.dispatch('wallet/loadPricingProduct');
    // this.$nextTick(() => {
    //   // Code that will run only after the
    //   // entire view has been rendered
    //   console.log('preloader struct cehck tick', this.$store.getters.isPreloading);
    //   if (this.$store.getters.isPreloading) {
    //     console.log('preloader struct cehck tick if', this.$store.getters.isPreloading);
    //     // this.$store.dispatch('setPreloader', false);
    //   }
    //   console.log('preloader struct cehck tick post', this.$store.getters.isPreloading);
    // });
  },
  // watch: {
  //   // call again the method if the route changes
  //   $route: 'fetchData',
  // },
  methods: {
    fetchData() {
      if (!this.$store.getters['auth/isLogged']) {
        return;
      }

      this.submitted = true;
      this.notValid = false;
      this.$store.dispatch('subject/loadSubject')
        .then(
          () => {
            this.submitted = false;
            console.debug('UserStructure loadsubject setpreloader', false);
            this.$store.dispatch('setPreloader', false);
          },
          (error) => {
            this.message = extractErrorMessage(error);
            this.submitted = false;
          },
        );
    },
    sendTokenRequest() {
      console.log('Send validation request');
      this.$confirm(
        {
          message: 'Vuoi richiedere la verifica della tua email?',
          button: {
            no: 'No',
            yes: 'Si',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: (confirm) => {
            if (confirm) {
              this.$store.dispatch('subject/sendTokenRequest').then(() => {
                this.submitted = false;
              },
              (error) => {
                this.message = extractErrorMessage(error);
                this.submitted = false;
              });
            }
          },
        },
      );
    },
    changePreloaderStatus(state) {
      this.hasLoaded = state;
      console.debug('UserStructure setpreloader', !state);
      this.$store.dispatch('setPreloader', !state);
    },
    loadProducts() {
      // console.log('prods', this.requestType, this.$store.getters['tableUtils/getTableList'](this.requestType));
      this.requestType.forEach((tableName) => {
        if (!this.$store.getters['tableUtils/hasTableList'](tableName)) {
          if (!this.$store.getters['tableUtils/isLoadingTableList'](tableName)) {
            this.$store.dispatch('tableUtils/loadTableList', {
              tableName,
              language: 'it',
            });
          }
        }
      });
    },
    routeTo(path) {
      this.$router.replace(path);
    },
    doCtaAction() {
      if (this.hasRoute) this.routeTo(this.titleButton.route);
      if (this.hasAction) this.titleButtonAction();
    },
    doBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>

</style>
